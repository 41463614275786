import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Hero title="404" content="You found a page that doesn't exist."/>
  </Layout>
)

export default NotFoundPage
